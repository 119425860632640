import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (

	<Layout pageName="404">

		<SEO title="404: Not found" keywords={[`homepage`, `redder`, `development`]} />

		<div className="hero">

			<div className="container">

				<h1>NOT FOUND</h1>
				<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
						
			</div>

		</div>

	</Layout>
)

export default NotFoundPage